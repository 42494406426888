import React, { useEffect, useState } from 'react'
import Head from 'react-helmet'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import LazyLoad from 'react-lazyload'

import { getContainerStyles } from '../components/ContentContainer'

import PageContainer from '../components/Layout/PageContainer'

import Layout from '../components/Layout'

import assetsIcon from './assetsIcon.png'
import cashIcon from './cashIcon.png'
import investmentsIcon from './investmentsIcon.png'
import netWorthIcon from './netWorthIcon.png'
import debtIcon from './debtIcon.png'

import assetsInfo from './assetsInfo.svg'
import cashInfo from './cashInfo.svg'
import investmentsInfo from './investmentsInfo.svg'
import netWorthInfo from './netWorthInfo.svg'
import debtInfo from './debtInfo.svg'

import assetsBox from './assetsBox.png'
import debtBox from './debtBox.png'
import cashBox from './cashBox.png'
import investmentsBox from './investmentsBox.png'
import netWorthBox from './netWorthBox.png'

import assetsMobileBox from './assetsMobileBox.png'
import debtMobileBox from './debtMobileBox.png'
import cashMobileBox from './cashMobileBox.png'
import investmentsMobileBox from './investmentsMobileBox.png'
import netWorthMobileBox from './netWorthMobileBox.png'

const styles = (theme) => {
  return createStyles({
    mainHeader: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 24,
      textAlign: 'center',
      marginTop: 135,
      marginBottom: 75,
      color: '#F0EDEF',
      [theme.breakpoints.up('sm')]: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 36,
        textAlign: 'center',
        marginTop: 135,
        marginBottom: 75,
      },
    },
    subHeader: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 16,
      color: '#F0EDEF',
      marginBottom: 35,
      textTransform: 'uppercase',

      [theme.breakpoints.up('sm')]: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 35,
        textTransform: 'uppercase',
      },
    },
    miniHeader: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 14,
      color: '#F0EDEF',
      marginBottom: 35,
      lineHeight: 1.65,

      [theme.breakpoints.up('sm')]: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 20,
        color: '#F0EDEF',
        marginBottom: 35,
      },
    },
    sub: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 14,
      color: '#F0EDEF',
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 20,
        color: '#F0EDEF',
        textTransform: 'uppercase',
      },
    },
    text: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: 1.65,
      color: '#F0EDEF',

      [theme.breakpoints.up('sm')]: {
        fontFamily: 'Montserrat',
        fontWeight: 'regular',
        fontSize: 18,
        lineHeight: 1.65,
      },
    },
    textBullet: {
      fontFamily: 'Montserrat',
      fontWeight: 'regular',
      fontSize: 13,
      lineHeight: 1.65,
      marginLeft: 30,
      color: '#F0EDEF',

      [theme.breakpoints.up('sm')]: {
        fontFamily: 'Montserrat',
        fontWeight: 'regular',
        fontSize: 18,
        lineHeight: 1.65,
        marginLeft: 30,
      },
    },
    textInside: {
      fontFamily: 'Montserrat',
      fontWeight: 'regular',
      fontSize: 13,
      lineHeight: 1.65,
      marginBottom: 60,
      color: '#F0EDEF',

      [theme.breakpoints.up('sm')]: {
        fontFamily: 'Montserrat',
        fontWeight: 'regular',
        fontSize: 18,
        lineHeight: 1.65,
        marginBottom: 60,
      },
    },
    divider: {
      height: .5,
      width: '100%',
      backgroundColor: '#EFEFEF',
      margin: '75px 0'
    },
    index: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      color: '#F0EDEF',
      fontWeight: 500,
      margin: 0,
      textDecoration: 'none',

      [theme.breakpoints.up('sm')]: {
        fontFamily: 'Montserrat',
        fontSize: 20,
        color: '#F0EDEF',
        fontWeight: 500,
        margin: 0,
        textDecoration: 'none',
      },
    },
    svg: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 500,
      },
    },
    boxHeader: {
      fontSize: 24,
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      color: '#4F4F4F',
      margin: 0,

      [theme.breakpoints.up('sm')]: {
        fontSize: 26,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: '#4F4F4F',
        margin: 0,
        whiteSpace: 'nowrap',
      },
    },
    boxText: {
      fontSize: 20,
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      color: '#4F4F4F',
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: '#4F4F4F',
        margin: 0,
      },
    },
    boxTextLast: {
      fontSize: 20,
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      color: '#4F4F4F',
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: '#4F4F4F',
        margin: 0,
      },
    },
    boxPlus: {
      fontSize: 36,
      fontFamily: 'Montserrat',
      color: '#D3D3D3',
      margin: '0 15px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        fontFamily: 'Montserrat',
        color: '#D3D3D3',
        margin: '0 15px',
      },
    },
    boxEqual: {
      fontSize: 50,
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      color: '#D3D3D3',
      margin: '0 35px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 50,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: '#D3D3D3',
        margin: '0 35px',
      },
    },
    boxImage: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: 50,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: '#D3D3D3',
        margin: '0 50px',
      },
    },
    mobileWrapper: {
      width: '85%',
      margin: '0 auto',
      [theme.breakpoints.up('sm')]: {
        // display: 'block'
      },
    }
  })
}

const Methodology = ({ classes }) => {

  const [windowWidth, setWindowWidth] = useState(0)

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)

    let searchParams = new URLSearchParams(window && window.location.search)
    const hasMobileQuery = searchParams.has('mobile')
    setIsMobile(hasMobileQuery)
  }, [])

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth)
  };

  return (
    <Layout>
      <Head>
        <title>Forecast Methodology</title>
      </Head>
      <PageContainer>
        <div className={classes.mobileWrapper}>
          <h1 className={classes.mainHeader} style={{ color: isMobile && '#000000' }}>
            Avocado Finance Forecast Methodology
          </h1>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            One of the hardest parts of personal finance is knowing how your Net Worth will change over time. Income, expenses, investments, debt, and assets can all feel like moving targets that make it hard to plan more than a couple of years out, so many people resort to simple rules of thumb and basic assumptions.
          </p>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            To help you build a smarter long-term plan and remove financial uncertainty out for your life, we built Avocado Finance. Our App combines your financial and personal data to project your financial future. With Avocado Finance, you can see your expected Net Worth for any date in the future and you’ll have all the data you need to plan ahead. Use it to make life’s most important personal finance decisions, achieve your financial goals, and ensure you are on path to financial freedom.
          </p>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            This document explains how Avocado Finance’s algorithm forecasts your financial future.
          </p>
          <div className={classes.divider} />
          <div style={{ width: '100%' }}>
            <p className={classes.subHeader} style={{ color: isMobile && '#000000' }}>
              TABLE OF CONTENTS
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15, }}>
              <img style={{ height: 20, width: 20, marginRight: 10, }} src={netWorthIcon} alt="" />
              <a href="#nw-section" className={classes.index} style={{ color: isMobile && '#000000' }}>Net Worth Forecast</a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15, }}>
              <img style={{ height: 20, width: 20, marginRight: 10, }} src={cashIcon} alt="" />
              <a href="#cash-section" className={classes.index} style={{ color: isMobile && '#000000' }}>Cash Balance Forecast</a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15, }}>
              <img style={{ height: 20, width: 20, marginRight: 10, }} src={investmentsIcon} alt="" />
              <a href="#investments-section" className={classes.index} style={{ color: isMobile && '#000000' }}>Investments Balance Forecast</a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15, }}>
              <img style={{ height: 20, width: 20, marginRight: 10, }} src={assetsIcon} alt="" />
              <a href="#assets-section" className={classes.index} style={{ color: isMobile && '#000000' }}>Assets Forecast</a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15, }}>
              <img style={{ height: 20, width: 20, marginRight: 10, }} src={debtIcon} alt="" />
              <a href="#debt-section" className={classes.index} style={{ color: isMobile && '#000000' }}>Debt Forecast</a>
            </div>
          </div>
          <div id="nw-section" className={classes.divider} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <img style={{ height: 20, width: 20, marginRight: 10, }} src={netWorthIcon} alt="" />
            <p className={classes.sub} style={{ color: isMobile && '#000000' }}>Net Worth Forecast</p>
          </div>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            The Net Worth Forecast shows your expected Net Worth balance for any date in the future.
          </p>
          <LazyLoad once >
            <div style={{ width: '100%', textAlign: 'center' }}>
              <object className={classes.svg} type="image/svg+xml" data={netWorthInfo} />
            </div>
          </LazyLoad>

          <div style={{ width: '100%', margin: '75px 0' }}>
            <img style={{ width: '100%' }} src={windowWidth < 760 ? netWorthMobileBox : netWorthBox} alt="" />
          </div>

          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            Your Net Worth is comprised of many different pieces. To get a true comprehensive picture of your finances, we take into consideration of all the moving variables. We break up Net Worth into 4 components: cash, investments, assets, and debt. Then, we forecast the 4 components individually and combine them to ensure accuracy. You’ll be able to see what your future Net Worth will look like for any date in the future, and in essence, your financial future. The following sections detail how each of the 4 components of your future Net Worth are individually calculated to create a representative picture of you.
          </p>
          <div id="cash-section" className={classes.divider} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <img style={{ height: 20, width: 20, marginRight: 10, }} src={cashIcon} alt="" />
            <p className={classes.sub} style={{ color: isMobile && '#000000' }}>Cash Balance Forecast</p>
          </div>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            The Cash Balance Forecast shows your expected Cash Balance for any date in the future.
          </p>
          <LazyLoad once >
            <div style={{ width: '100%', textAlign: 'center' }}>
              <object className={classes.svg} type="image/svg+xml" data={cashInfo} />
            </div>
          </LazyLoad>

          <div style={{ width: '100%', margin: '75px 0' }}>
            <img style={{ width: '100%' }} src={windowWidth < 760 ? cashMobileBox : cashBox} alt="" />
          </div>

          <p className={classes.miniHeader} style={{ color: isMobile && '#000000' }}>(A) Today’s Cash Balance from Your Synced Accounts</p>
          <p className={classes.textInside} style={{ color: isMobile && '#000000' }}>
            The amount of cash you have today from your synced financial accounts. We get this data from your synced accounts and it’s updated on its own as your balance goes up or down. The types of accounts included are checkings, savings, and money market accounts.
          </p>

          <p className={classes.miniHeader} style={{ color: isMobile && '#000000' }}>(B) Your Cash Inflow</p>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            The amount of cash you are expected to earn. There are two types of Cash Inflow.
          </p>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (1) Your Income
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Your after-tax income
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • We ask you to provide your annual salary, state location, filing status. Our algorithm automatically calculates and deducts your state and federal taxes, just like the IRS does
          </p>
          <br />
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (2) Other Inflow
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Other sources of cash that increases your Cash Balance (such as one-time cash deposits)
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • This cash inflow is added to your Cash Balance automatically when you deposit the cash into a synced financial account
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • In example, if you are gifted $500 of cash from family, your Cash Balance will go up automatically when you deposit that $500 into your synced financial account
          </p>
          <br />
          <br />
          <p className={classes.miniHeader} style={{ color: isMobile && '#000000' }}>(C) Your Cash Outflow</p>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            The amount of cash that you are expecting to spend. There are two types of Cash Outflow.
          </p>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (1) Your Spend
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Your regular expenses
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • We ask you to provide your recurring expenses such as car payments, mortgage payments, rent, health insurance, Netflix, utilities, and more
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • With that information, we are able to predict accurately and subtract how much money you are expected to spend for any selected time frame
          </p>
          <br />
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (2) Other Outflow
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Other uses of cash that decreases your Cash Balance (such as one-time purchases)
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • This cash outflow will be subtracted from your Cash Balance automatically when you spend the money
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • In example, if you make an unexpected purchase of $500, your Cash Balance will go down automatically when the charge shows up in your synced financial account
          </p>

          <div id="investments-section" className={classes.divider} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <img style={{ height: 20, width: 20, marginRight: 10, }} src={investmentsIcon} alt="" />
            <p className={classes.sub} style={{ color: isMobile && '#000000' }}>INVESTMENTS BALANCE FORECAST</p>
          </div>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            The Investments Balance Forecast shows your expected Investments Balance for any date in the future.
          </p>
          <br />
          <LazyLoad once>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <object className={classes.svg} type="image/svg+xml" data={investmentsInfo} />
            </div>
          </LazyLoad>

          <div style={{ width: '100%', margin: '75px 0' }}>
            <img style={{ width: '100%' }} src={windowWidth < 760 ? investmentsMobileBox : investmentsBox} alt="" />
          </div>

          <p className={classes.miniHeader} style={{ color: isMobile && '#000000' }}>(A) Today’s Investments Balance from Your Synced Accounts</p>
          <p className={classes.textInside} style={{ color: isMobile && '#000000' }}>
            The amount of investments you have today from your synced financial accounts. We get this data from your synced accounts and it’s updated on its own as your balance goes up or down. The types of accounts included are brokerages, retirement accounts (401k, 403b, 457, SEP, IRA), and others.
          </p>
          <p className={classes.miniHeader} style={{ color: isMobile && '#000000' }}>(B) 7.3% Average Annual Return</p>
          <p className={classes.textInside} style={{ color: isMobile && '#000000' }}>
            The expected return rate for your current investments.
          </p>

          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (1) Why 7.3%?
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • The S&P 500 Index is the benchmark of the United States stock market performance dating back to 1957
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • The S&P 500 has returned 10.5% every year in average
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Adjusting for inflation, we assume 7.3% average annual growth for your currently held investments
          </p>
          <br />
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (2) What is the S&P (Standard & Poor’s) 500?
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • The S&P 500 measures the value of the stocks of the 500 largest US corporations by market capitalization listed on the New York Stock Exchange or Nasdaq Composite
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • The S&P 500 is considered an effective representation for the economy by covering all areas of the United States and across all industries
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • To ensure it is representative of the United States economy, the companies in the S&P 500 are monitored and readjusted as new companies grow to be in the top 500 and legacy companies fall out
          </p>
          <br />
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (3) Bottomline
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Owning and investing in stocks, index funds, mutual funds, ETFs and alike is a key driver in growing your wealth
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Those who’ve accumulated significant amounts of wealth have done so by earning a living, saving, and investing over decades
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Starting early is always better because more time in the market means less volatility, less risk, and more compound interest growth for your portfolio
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • The more you put into your investments portfolio, the better your financial future will look
          </p>

          <div id="assets-section" className={classes.divider} />

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <img style={{ height: 20, width: 20, marginRight: 10, }} src={assetsIcon} alt="" />
            <p className={classes.sub} style={{ color: isMobile && '#000000' }}>ASSETS BALANCE FORECAST</p>
          </div>

          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            The Assets Balance Forecast shows your expected Assets Balance for any date in the future.
          </p>

          <br />

          <LazyLoad once>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <object className={classes.svg} type="image/svg+xml" data={assetsInfo} />
            </div>
          </LazyLoad>

          <div style={{ width: '100%', margin: '75px 0' }}>
            <img style={{ width: '100%' }} src={windowWidth < 760 ? assetsMobileBox : assetsBox} alt="" />
          </div>

          <p className={classes.miniHeader} style={{ color: isMobile && '#000000' }}>
            (A) Today’s Assets Balance from Your Synced Accounts
          </p>
          <p className={classes.textInside} style={{ color: isMobile && '#000000' }}>
            The amount of assets you have today from your synced financial accounts. We get this data from your synced accounts and it’s updated on its own as the current value moves up or down.
          </p>
          <p className={classes.miniHeader} style={{ color: isMobile && '#000000' }}>
            (B) 1.8% Average Annual Return for Appreciating Assets
          </p>
          <p className={classes.textInside} style={{ color: isMobile && '#000000' }}>
            The expected return rate for your current appreciating assets.
          </p>

          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (1) What is an appreciating asset?
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • An appreciating asset increases in value over time
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Examples of appreciating assets include real estate, such as single family homes, duplexes, and apartment complexes
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Due to economic growth, limited housing, demographic changes, or other additional reasons, real estate prices are almost guaranteed to go up in the long run
          </p>
          <br />
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (2) Why 1.8%?
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Since 1913, the US housing prices have increased by 5% in average
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Adjusting for inflation, we assume 1.8% average annual growth for your current appreciating assets
          </p>
          <br />
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (3) Bottomline
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Owning and investing in appreciating assets is a key driver in growing your wealth
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Those who’ve accumulated significant amounts of wealth have done so by earning a living, saving, and investing over decades
          </p>
          <br />
          <p className={classes.miniHeader} style={{ color: isMobile && '#000000' }}>
            (C) 0% Average Annual Return for Depreciating Assets
          </p>
          <p className={classes.textInside} style={{ color: isMobile && '#000000' }}>
            The expected return rate for your current depreciating assets.
          </p>

          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (1) What is a depreciating asset?
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • A depreciating asset decreases in value over time
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Examples of depreciating assets include cars, boats, jewelry, equipment, and more
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • It is difficult to project the future of depreciating assets, as they each vary and unpredictable factors can affect the value of such assets
          </p>
          <br />
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (2) Why 0%?
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Given the unpredictable nature of depreciating assets, we assume the value to stay constant over time
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • You are able to manually adjust the value of depreciating assets as time passes
          </p>
          <br />
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (3) Bottomline
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • While depreciating assets provide fun and are sometimes necessary, we recommend limiting depreciating assets in your portfolio to maximize your net worth growth and trajectory
          </p>

          <div id="debt-section" className={classes.divider} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <img style={{ height: 20, width: 20, marginRight: 10, }} src={debtIcon} alt="" />
            <p className={classes.sub} style={{ color: isMobile && '#000000' }}>DEBT BALANCE FORECAST</p>
          </div>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            The Debt Balance Forecast shows your expected Debt Balance for any date in the future.
          </p>
          <LazyLoad once>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <object className={classes.svg} type="image/svg+xml" data={debtInfo} />
            </div>
          </LazyLoad>

          <div style={{ width: '100%', margin: '75px 0' }}>
            <img style={{ width: '100%' }} src={windowWidth < 760 ? debtMobileBox : debtBox} alt="" />
          </div>

          <p className={classes.miniHeader} style={{ color: isMobile && '#000000' }}>
            (A) Today’s Debt Balance from Your Synced Accounts
          </p>
          <p className={classes.textInside} style={{ color: isMobile && '#000000' }}>
            The amount of debt you have today from your synced financial accounts. We get this data from your synced accounts
            and it’s updated on its own as your balance goes up or down. The types of accounts included are mortgages, student loans, auto
            loans, personal loans, and credit cards.
          </p>
          <p className={classes.miniHeader} style={{ color: isMobile && '#000000' }}>
            (B) Your Debt Payments
          </p>
          <p className={classes.textInside} style={{ color: isMobile && '#000000' }}>
            The regular monthly debt payments you pay every month.
          </p>
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (1) Your Loan Payments
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Your recurring loan payments
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • We ask you to connect your loan financial institutions
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • With that information, we are able to predict and subtract how much you will pay in loan payments every month and when your payments will end
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Your current loan balance is always updated for the latest balance
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Your future loan balance is calculated by subtracting the number of debt payments you will make in the selected frame
          </p>
          <br />
          <p className={classes.text} style={{ color: isMobile && '#000000' }}>
            (2) Credit Cards
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Your outstanding credit card balances
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • We ask you to connect your credit card institutions
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • Given people’s credit card payment habits vary greatly, we do not forecast future credit card balances
          </p>
          <p className={classes.textBullet} style={{ color: isMobile && '#000000' }}>
            • While we don’t forecast your credit card balance to go up or down, note that if you do pay off balances, the current balance will automatically update for you
          </p>
        </div>
      </PageContainer>
    </Layout>
  )
}

export default withStyles(styles)(Methodology)
